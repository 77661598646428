import React from 'react'
import axios from 'axios'
import queryString from 'query-string'

class SvcDevices extends React.Component {
  // get all devices
  getDevices(config, submission) {
    const queryParameters = {
      handhelds: {
        serial_number: null,
        mac_address: null,
        model_name: null,
        operating_system_version: null,
        last_user: null,
        location_id: null,
        id: null,
        unique_id: null,
        tcin: null,
      },
      devices: {
        id: null,
        manufacturer_name: null,
        model_name: null,
        serial_number: null,
        updated_on: null,
        classification_name: null,
        ip_address: null,
        location_id: null,
        region_id: null,
        group_id: null,
        district_id: null,
        mac_address: null,
        operating_system_version: null,
        operating_system: null,
        page: null,
        per_page: null,
        department: null,
        device_state: null,
        management_system: null,
        operating_system_name: null,
        device_event_state: null,
        domain_name: null,
        'products.name': null,
        'products.version_name': null,
        'insights.bios_version': null,
        'insights.ram': null,
        'insights.ad_organizational_unit': null,
        'insights.os_version': null,
        state: null,
        'insights.ci_installed_application.name': null,
        'insights.ci_installed_application.version': null,
        'insights.location_type': null,
        windows_not: null,
        status: null,
        unique_id: null,
        tcin: null,
        location_type: null,
      },
    }
    let params = []
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.devices.hasOwnProperty(param[0])) {
        if (param[0] === 'classification_name') {
          Object.entries(submission).forEach((row) => {
            params.push(config.deviceClassificationMap[row[1]])
          })
          queryParametersVar.devices[`${param[0]}`] = params
        } else {
          queryParametersVar.devices[`${param[0]}`] = param[1]
        }
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    if (queryParametersVar.devices['products.name'] != null) {
      queryParametersVar.devices['products.package_name'] =
        queryParametersVar.devices['products.name']
      delete queryParametersVar.devices['products.name']
    }
    let endpointParams = queryString.stringify(
      Object.assign(queryParametersVar.devices, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
      },
    )
    var apiUri = apiUriBase + `/devices?` + endpointParams
    return axios.get(apiUri)
  }

  getAdvancedSearchDevices(config, mapping, submission) {
    mapping.push('page', 'per_page', 'advanced')
    let queryParameters = {}
    console.log(mapping)
    console.log(submission)
    Object.entries(submission).forEach((param) => {
      if (
        ['page', 'per_page', 'advanced'].includes(param[0]) ||
        mapping.find((field) => {
          return field?.field_name === param[0]
        }) !== undefined
      ) {
        queryParameters[`${param[0]}`] = param[1]
      }
    })

    if (
      Object.keys(submission).length !== Object.keys(queryParameters).length
    ) {
      delete queryParameters.page
      delete queryParameters.per_page

      let searchText = queryString.stringify(Object.assign(queryParameters), {
        sort: false,
        skipNull: true,
      })
      window.location = window.location.origin + `/devices?${searchText}`
    }

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    let endpointParams = queryString.stringify(
      Object.assign(queryParameters, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
      },
    )
    var apiUri = apiUriBase + `/devices?` + endpointParams
    return axios.get(apiUri)
  }

  getAdvancedSearchMapping(auth, param) {
    const apiUriBase = auth.apiUriBase
    const apiKey = auth.apiKey

    let queryParameters = {}
    queryParameters['key'] = apiKey
    if (param && param !== 'all') {
      queryParameters['classification_name'] = param
    }
    let endpointParams = queryString.stringify(Object.assign(queryParameters), {
      sort: false,
      skipNull: true,
    })
    var apiUri = apiUriBase + `/field_metadata?` + endpointParams
    return axios.get(apiUri)
  }

  // get device type counts by location
  getDeviceCountsLocation(config, submission) {
    const queryParameters = {
      devices: {
        classification_name: null,
        location_id: null,
        field_name: null,
        manufacturer_name: null,
        windows_not: null,
      },
    }
    let queryParametersVar = queryParameters
    Object.entries(submission).forEach((param) => {
      if (queryParametersVar.devices.hasOwnProperty(param[0])) {
        queryParametersVar.devices[`${param[0]}`] = param[1]
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    let endpointParams = queryString.stringify(
      Object.assign(queryParametersVar.devices, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
      },
    )
    var apiUri = apiUriBase + `/device_type_counts?` + endpointParams
    return axios.get(apiUri)
  }

  //get device counts
  async getDeviceCounts(config, gridDetails, tabLocationName) {
    const queryParameters = {
      devices: {
        model_name: null,
        classification_name: null,
        domain_name: null,
        department: null,
        region_id: null,
        field_name: null,
        manufacturer_name: null,
        windows_not: null,
        location_type:
          !tabLocationName || tabLocationName === 'All'
            ? null
            : tabLocationName,
      },
    }
    let params = []
    let queryParametersVar = queryParameters
    Object.entries(gridDetails.count_parameters).forEach((param) => {
      if (queryParametersVar.devices.hasOwnProperty(param[0])) {
        if (param[0] === 'classification_name' || param[0] === 'domain_name') {
          if (gridDetails.name === 'devicesAll') {
            queryParametersVar.devices[`${param[0]}`] = param[1]
          } else if (
            gridDetails.name === 'devicesAllEndUserEndPoint' ||
            gridDetails.name === 'devicesI10iEndUserEndPoint'
          ) {
            Object.values(param[1]).forEach((row) => {
              params.push(config.deviceClassificationMap[row])
              queryParametersVar.devices[`${param[0]}`] = params
            })
          } else {
            queryParametersVar.devices[`${param[0]}`] =
              config.deviceClassificationMap[param[1]]
          }
        } else {
          queryParametersVar.devices[`${param[0]}`] = param[1]
        }
      }
    })

    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiQuery = gridDetails.count_url

    let endpointParams = queryString.stringify(
      Object.assign(queryParametersVar.devices, { key: apiKey }),
      {
        sort: false,
        skipNull: true,
        arrayFormat: 'separator',
        arrayFormatSeparator: ',',
      },
    )
    var apiUri = apiUriBase + apiQuery + endpointParams
    return axios.get(apiUri)
  }
  // get device location details
  getDeviceLocations(serialNumber, lastKnownLocations, config) {
    const apiUriBase = config.auth.apiUriBase
    const apiKey = config.auth.apiKey
    const apiUri = encodeURI(
      `${apiUriBase}/device_locations/${serialNumber}?last_known_locations=${lastKnownLocations}&key=${apiKey}`,
    )
    return axios.get(apiUri)
  }
  getmyDeviceUtilization(props, state, download) {
    const queryParameters = {
      devices: {
        location_id: null,
        district_id: null,
        group_id: null,
        region_id: null,
      },
    }
    let queryParametersVar = queryParameters
    if (queryParametersVar.devices.hasOwnProperty(state.selected)) {
      if (state.searchText === 'All') {
        queryParametersVar.devices[state.selected] =
          props.env.devicesSearchItems[
            state.selected
          ].search_suggestions.toString()
      } else {
        queryParametersVar.devices[state.selected] = state.searchText
      }
    }
    const apiUriBase = props.env.auth.apiUriBase
    const apiKey = props.env.auth.apiKey

    let endpointParams = queryString.stringify(
      Object.assign(
        queryParametersVar.devices,
        { start_date: state.startDate },
        { end_date: state.endDate },
        { download: download },
        { key: apiKey },
      ),
      {
        sort: false,
        skipNull: true,
      },
    )
    const apiUri =
      apiUriBase + '/handhelds/max_store_utilizations?' + endpointParams
    return axios.get(apiUri)
  }

  getmyCheckoutUtilization(props, state, download) {
    const queryParameters = {
      devices: {
        location_id: null,
        district_id: null,
        group_id: null,
        region_id: null,
      },
    }
    let queryParametersVar = queryParameters
    if (queryParametersVar.devices.hasOwnProperty(state.selected)) {
      if (state.searchText === 'All') {
        queryParametersVar.devices[state.selected] =
          props.env.devicesSearchItems[
            state.selected
          ].search_suggestions.toString()
      } else {
        queryParametersVar.devices[state.selected] = state.searchText
      }
    }
    const apiUriBase = props.env.auth.apiUriBase
    const apiKey = props.env.auth.apiKey

    let endpointParams = queryString.stringify(
      Object.assign(
        queryParametersVar.devices,
        { start_date: state.startDate },
        { end_date: state.endDate },
        { download: download },
        { key: apiKey },
      ),
      {
        sort: false,
        skipNull: true,
      },
    )
    const apiUri =
      apiUriBase + '/handhelds/mycheckout_store_utilizations?' + endpointParams
    return axios.get(apiUri)
  }

  getKioskUtilization(props, state, download) {
    const queryParameters = {
      devices: {
        location_id: null,
        district_id: null,
        group_id: null,
        region_id: null,
      },
    }
    let queryParametersVar = queryParameters
    if (queryParametersVar.devices.hasOwnProperty(state.selected)) {
      if (state.searchText === 'All') {
        queryParametersVar.devices[state.selected] =
          props.env.devicesSearchItems[
            state.selected
          ].search_suggestions.toString()
      } else {
        queryParametersVar.devices[state.selected] = state.searchText
      }
    }
    const apiUriBase = props.env.auth.apiUriBase
    const apiKey = props.env.auth.apiKey

    let endpointParams = queryString.stringify(
      Object.assign(
        queryParametersVar.devices,
        { start_date: state.startDate },
        { end_date: state.endDate },
        { download: download },
        { key: apiKey },
      ),
      {
        sort: false,
        skipNull: true,
      },
    )
    const apiUri = apiUriBase + '/tablets/kiosk_utilizations?' + endpointParams
    return axios.get(apiUri)
  }

  generateAcornLogLink(serial_number) {
    return `https://logs-prod.prod.target.com/app/kibana#/discover?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))&_a=(columns:!(host.name,labels.application,host.id,message),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:f651df50-b167-11ea-8c0f-336ba2e98d45,key:agent.name,negate:!f,params:(query:AcornLogging),type:phrase),query:(match:(agent.name:(query:AcornLogging,type:phrase)))),('$state':(store:appState),meta:(alias:!n,disabled:!f,index:f651df50-b167-11ea-8c0f-336ba2e98d45,key:host.id,negate:!f,params:(query:${serial_number}),type:phrase),query:(match:(host.id:(query:${serial_number},type:phrase))))),index:f651df50-b167-11ea-8c0f-336ba2e98d45,interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))`
  }
}
export default SvcDevices
