import {
  Bar,
  BarChart,
  Cell,
  Tooltip as ChartTooltip,
  Legend,
  Pie,
  PieChart,
  XAxis,
  YAxis,
} from 'recharts'

import CloseIcon from '@material-ui/icons/Close'
import CustomCheckOutDownloadDialog from './CustomCheckOutDownloadDialog'
import DeviceUtilization from './DeviceUtilization'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import GridListTileBar from '@material-ui/core/GridListTileBar'
import GridPlatforms from './GridPlatforms'
import IconButton from '@material-ui/core/IconButton'
import Label from '@material-ui/icons/Label'
import React from 'react'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import SvcI10iDevices from '../../services/svcI10iDevices'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import l3Classifications from '../Devices/I10iExports/l3Classifications'
import l4DeviceMap from '../Devices/I10iExports/l4DeviceMap'
import queryString from 'query-string'
import svcDevices from '../../services/svcDevices'
import { withAnalytics } from '@praxis/component-analytics'
import { withEnv } from '@praxis/component-runtime-env'
import { withStyles } from '@material-ui/core/styles'

class Griddler extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      dialogOpen: false,
      tabLocationName: '',
    }
    this.svcDevices = new svcDevices()
    this.svcI10iDevices = new SvcI10iDevices()
    this.counts = []
    this.testCount = 0
    this.countsEndUserEndPoint = 0
    this.totalCount = []
    this.I10iCounts = []
    this.finalCount = 0
    this.apiSucceeded = true
  }

  getTotalCount = async (row) => {
    this.finalCount = row[1] + this.countsEndUserEndPoint
  }

  getEndUserEndPointCounts = async () => {
    await this.svcDevices
      .getDeviceCounts(
        this.props.env,
        this.props.env.landingPageWidgets['devicesAllEndUserEndPoint'],
        this.props.tabLocationName,
      )
      .then((response) => {
        Object.entries(response.data).forEach((row, index) => {
          this.countsEndUserEndPoint += row[1]
        })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'DeviceTypeCount',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDeviceTypeCount`,
          },
        }
        this.props.trackEvent(eventData)
      })
  }

  getI10iEndUserEndPointCounts = async () => {
    await this.svcDevices
      .getDeviceCounts(
        this.props.env,
        this.props.env.landingPageWidgets['devicesI10iEndUserEndPoint'],
        this.props.tabLocationName,
      )
      .then((response) => {
        Object.entries(response.data)
          .sort(function (a, b) {
            return a[1] > b[1] ? -1 : 1
          })
          .forEach((row, index) => {
            this.counts.push({ name: row[0], value: row[1] })
          })
      })
      .catch((error) => {
        const eventData = {
          customMetrics: {
            metric1: 'DeviceTypeCount',
            metric3: error.response.status,
          },
          event: {
            type: `apiErrorDeviceTypeCount`,
          },
        }
        this.props.trackEvent(eventData)
      })
  }

  async componentDidMount() {
    this._isMounted = true
    this.setState({ deviceCountsLoading: true })

    if (this.props.gridDetails.name === 'devicesAll') {
      await this.getEndUserEndPointCounts()
    }

    if (this.props.gridDetails.name === 'devicesAllEndUserEndPoint') {
      await this.getI10iEndUserEndPointCounts()
    }

    if (l4DeviceMap.includes(this.props.gridDetails.name)) {
      // this block of code gets the tcin counts
      this.svcI10iDevices
        .getI10iCounts(
          this.props.env,
          this.props.gridDetails.count_url,
          this.props.gridDetails.name,
        )
        .then((response) => {
          let data = response.data

          // this block of code pulls in the total l4 device counts and renders the appropriate widgets
          if (this.props.gridDetails.name === 'devicesI10iAll') {
            const alias = {
              registerI10i: 'register',
              printerI10i: 'printer',
            }
            let property =
              alias[this.props.widgetProperty] || this.props.widgetProperty

            data.map((entry) => {
              if (String(entry.name) === String(property)) {
                return (this.testCount = entry.count)
              }
              return null
            })

            return
          }

          for (var k = 0; k < data.length; k++) {
            let display
            data[k].manufacturer.includes('bad_manufacturer') ||
            data[k].model.includes('bad_model')
              ? (display = 'Bad Model/Manufacturer')
              : (display = data[k].manufacturer + ' ' + data[k].model)
            if (!(parseInt(data[k].count) === 0)) {
              this.counts.push({
                name: data[k].tcin,
                value: parseInt(data[k].count),
                displayName: display,
              })
            }
          }

          this.counts.sort(function (a, b) {
            return a.value - b.value
          })
          this.counts.reverse()
          const eventData = {
            customMetrics: {
              metric1: 'DeviceTypeCount-I10i',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessDeviceTypeCount-I10i`,
            },
          }

          this.props.trackEvent(eventData)
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'DeviceTypeCount-I10i',
              metric3: error?.response?.status || 500,
            },
            event: {
              type: `apiErrorDeviceTypeCount-I10i`,
            },
          }
          this.props.trackEvent(eventData)
          this.apiSucceeded = false
        })
        .finally(() => {
          this._isMounted && this.setState({ deviceCountsLoading: false })
        })
    } else if (
      this.props.gridDetails.count_url !== null &&
      this.props.gridDetails.name !== 'devicesHandheldUtilization' &&
      this.props.gridDetails.name !== 'devicesTabletUtilization'
    ) {
      this.svcDevices
        .getDeviceCounts(
          this.props.env,
          this.props.gridDetails,
          this.props.tabLocationName,
        )
        .then((response) => {
          const eventData = {
            customMetrics: {
              metric1: 'DeviceTypeCount',
              metric3: response.status,
            },
            event: {
              type: `apiSuccessDeviceTypeCount`,
            },
          }

          this.props.trackEvent(eventData)

          Object.entries(response.data)
            .sort(function (a, b) {
              return a[1] > b[1] ? -1 : 1
            })
            .flatMap(async (row, index) => {
              if (row[0] !== 'EndUser/EndPoint') {
                this.counts.push({ name: row[0], value: row[1] })
              } else {
                await this.getTotalCount(row)
                this.counts.push({ name: row[0], value: this.finalCount })
              }
            })
        })
        .catch((error) => {
          const eventData = {
            customMetrics: {
              metric1: 'DeviceTypeCount',
              metric3: error.response.status,
            },
            event: {
              type: `apiErrorDeviceTypeCount`,
            },
          }
          this.props.trackEvent(eventData)
        })
        .finally(() => {
          if (
            this.props.gridDetails.name.match(
              /^(devicesAllEndUserEndPoint|devicesAll)$/,
            )
          ) {
            this.counts.sort((a, b) =>
              a.value < b.value ? 1 : b.value < a.value ? -1 : 0,
            )
          }
          this._isMounted && this.setState({ deviceCountsLoading: false })
        })
    } else {
      this._isMounted && this.setState({ deviceCountsLoading: false })
    }
  }

  total() {
    this.counts.length >= 1 &&
      this.totalCount.push({
        name: 'total',
        value: this.counts
          .flatMap((item) => item.value)
          .reduce((prev, next) => prev + next, 0),
      })
    if (this.totalCount[0] !== undefined) {
      return this.totalCount[0].value
    }
  }
  // cameras() {
  //   return this.counts.length >= 1 && this.counts[0].value
  // }
  printers() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  handhelds() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  windows() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  mac() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  tablets() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  bluetoothscanner() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  rfidscanner() {
    return this.counts.length >= 1 && this.counts[0].value
  }
  i10i(deviceTypeList) {
    return this.counts.length >= 1 && this.counts[0].value
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  componentDidUpdate(prevProps) {
    if (this.props.tabLocationName !== prevProps.tabLocationName) {
      this.setState({ data: [] })
      this.counts = []
      this.testCount = 0
      this.countsEndUserEndPoint = 0
      this.totalCount = []
      this.I10iCounts = []
      this.finalCount = 0
      this.apiSucceeded = true
      this.componentDidMount()
    }
  }

  handleDialogOpen = (details, row) => {
    this.setState({
      dialogOpen: true,
      dialogDetails: details,
      dialogHeader: row,
    })
  }
  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
      dialogDetails: null,
      dialogHeader: null,
    })
  }

  footer = (gridDetails) => {
    return (
      <GridListTileBar
        onClick={() =>
          gridDetails.navigate_url !== null &&
          this.handleDialogOpen(gridDetails, gridDetails.name)
        }
        title={gridDetails.title}
        style={{
          textAlign: 'center',
          background: '#999999',
        }}
        actionIcon={
          gridDetails.navigate_url !== null && (
            <IconButton>
              {this.state.dialogHeader === gridDetails.name ? (
                <ExpandLess />
              ) : (
                <ExpandMore />
              )}
            </IconButton>
          )
        }
      ></GridListTileBar>
    )
  }

  onWidgetClick = (props) => {
    const eventData = {
      customInteraction: {
        key: `WidgetOnClick`,
        value:
          this.props.gridDetails.count_parameters.classification_name +
          '-' +
          this.props.gridDetails.count_parameters.field_name,
      },
    }
    this.props.trackEvent(eventData)
    let endpointParams = queryString.stringify(
      l3Classifications.includes(
        props.gridDetails.count_parameters.classification_name,
      )
        ? Object.assign(
            {
              classification_name:
                props.gridDetails.count_parameters.classification_name,
            },
            {
              tcin: props.value,
            },
            {
              device_type: props.gridDetails.count_parameters.device_type,
            },
          )
        : props.gridDetails.count_parameters.hasOwnProperty('windows_not')
          ? Object.assign(
              {
                classification_name:
                  props.gridDetails.count_parameters.classification_name,
              },
              {
                [props.gridDetails.count_parameters.field_name]: props.value,
              },
              {
                windows_not: props.gridDetails.count_parameters.windows_not,
              },
            )
          : props.gridDetails.count_parameters.hasOwnProperty(
                'manufacturer_name',
              )
            ? Object.assign(
                {
                  classification_name:
                    props.gridDetails.count_parameters.classification_name,
                },
                {
                  [props.gridDetails.count_parameters.field_name]: props.value,
                },
                {
                  manufacturer_name:
                    props.gridDetails.count_parameters.manufacturer_name,
                },
              )
            : Object.assign(
                {
                  classification_name:
                    props.gridDetails.count_parameters.classification_name,
                },
                {
                  [props.gridDetails.count_parameters.field_name]: props.value,
                },
              ),
      {
        sort: false,
        skipNull: true,
      },
    )

    if (
      props.gridDetails.name === 'devicesBTScannersStates' ||
      props.gridDetails.name === 'devicesRFIDScannersStates' ||
      props.gridDetails.count_parameters.classification_name === 'Mac' ||
      l3Classifications.includes(
        props.gridDetails.count_parameters.classification_name,
      )
    ) {
      // this is where the window gets oriented to the device table view ...
      window.location = window.location.origin + `/devices?${endpointParams}`
    } else if (
      props.gridDetails.name === 'devicesHandheldStates' ||
      props.gridDetails.name === 'devicesTabletStates'
    ) {
      window.location =
        window.location.origin +
        `/devices?${endpointParams}` +
        (this.props.tabLocationName !== ''
          ? `&location_type=${this.props.tabLocationName}`
          : '')
    } else if (
      props.gridDetails.count_parameters.classification_name === 'Windows'
    ) {
      window.location =
        window.location.origin +
        `/devices?${endpointParams}` +
        (this.props.tabLocationName !== ''
          ? `&insights.location_type=${this.props.tabLocationName}*`
          : '')
    } else if (
      props.gridDetails.count_parameters.classification_name ===
        'Bluetooth Scanner' ||
      props.gridDetails.count_parameters.classification_name === 'RFID' ||
      props.gridDetails.count_parameters.classification_name === 'Printer'
    ) {
      window.location =
        window.location.origin +
        `/devices?${endpointParams}&device_state=Online|Offline`
    } else if (props.gridDetails.name === 'devicesHandheldManagementSystem') {
      window.location =
        window.location.origin +
        `/devices?${endpointParams}&device_state=Online|Offline|Enrolled|Bonded|Quarantined|Repair|Blocked|Converted|Stolen` +
        (this.props.tabLocationName !== ''
          ? `&location_type=${this.props.tabLocationName}`
          : '')
    } else if (props.gridDetails.name === 'deviceLockerStates') {
      window.location =
        window.location.origin +
        `/devices?${endpointParams}&device_state=Online|Offline|Bonded` +
        (this.props.tabLocationName !== ''
          ? `&location_type=${this.props.tabLocationName}`
          : '')
    } else {
      window.location =
        window.location.origin +
        `/devices?${endpointParams}&device_state=Online|Offline|Enrolled|Bonded|Quarantined|Repair|Blocked|Converted|Stolen` +
        (this.props.tabLocationName !== ''
          ? `&location_type=${this.props.tabLocationName}`
          : '')
    }
  }

  onWidgetClickAllDevices = (props) => {
    window.location =
      window.location.origin + `/devices?classification_name=${props.value}`
  }

  onWidgetClickEndUserEndPointDevices = (props) => {
    let params = []
    params.push(props.deviceClassificationMapReverse[props.value])
    if (props.value === 'windows' || props.value === 'mac') {
      window.location =
        window.location.origin + `/devices?classification_name=${params}`
    } else if (
      props.value === 'bluetoothscanner' ||
      props.value === 'rfid' ||
      props.value === 'printer'
    ) {
      window.location =
        window.location.origin +
        `/devices?classification_name=${params}&device_state=Online|Offline`
    } else if (props.value.match(/^(rfidHandheld|scale|register)$/)) {
      window.location =
        window.location.origin +
        `/devices?classification_name=EndUser/EndPoint&device_type=${props.value}`
    } else {
      window.location =
        window.location.origin +
        `/devices?classification_name=${params}&device_state=Online|Offline|Lost|Enrolled|Bonded|Quarantined|Repair|Deleted|Blocked|Converted|Stolen`
    }
  }

  render() {
    const { classes, gridDetails, deviceClassificationMapReverse } = this.props
    const { dialogDetails, dialogOpen } = this.state

    const COLORS = [
      '#0097a7',
      '#c2185b',
      '#ffa000',
      '#ffce19',
      '#00796b',
      '#303f9f',
      '#7023b7',
      '#244224',
      '#784d41',
      '#5b5c58',
    ]
    const COLORS1 = {
      Offline: '#b85300',
      Online: '#008300',
      Lost: '#0097a7',
      Enrolled: '#c2185b',
      Bonded: '#ffa000',
      Quarantined: '#ffce19',
      Repair: '#00796b',
      Deleted: '#303f9f',
      Blocked: '#7023b7',
      Converted: '#3366cc',
      Stolen: '#888',
    }

    const COLORS2 = {
      'On Power': '#008300',
      'In Use': '#e2ba0c',
      'No Check-Out': '#b92c09',
      'Use Expired': '#b85300',
      Repair: '#888',
    }

    return this.state.deviceCountsLoading ? (
      <Spinner layout="selfCentering" />
    ) : (
      <React.Fragment>
        {gridDetails.name === 'welcomeMickra' && (
          <>
            <Typography
              style={{ paddingTop: 15, marginLeft: 5 }}
              align="center"
              variant="h3"
            >
              {gridDetails.title}
            </Typography>
            <Typography
              align="center"
              style={{
                paddingTop: 55,
                marginLeft: 5,
                fontSize: 18,
                color: 'black',
                textAlign: 'justify',
              }}
              variant="h5"
            >
              {gridDetails.info}
            </Typography>
          </>
        )}

        {gridDetails.name === 'devicesAllEndUserEndPoint' && (
          // this is the chart at the top of the page
          <>
            <PieChart
              width={gridDetails.layout.w * 0.9}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClickEndUserEndPointDevices}
                    deviceClassificationMapReverse={
                      deviceClassificationMapReverse
                    }
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />

              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={(e) => {
                      this.onWidgetClickEndUserEndPointDevices({
                        deviceClassificationMapReverse:
                          deviceClassificationMapReverse,
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            <Typography
              align="left"
              style={{ paddingTop: 5, marginLeft: 5, fontSize: 18 }}
              variant="button"
            >
              Total Devices: {this.total()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}

        {gridDetails.name === 'devicesAll' && (
          // this is the chart at the top of the page
          <>
            <PieChart
              width={gridDetails.layout.w * 0.9}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClickAllDevices}
                    deviceClassificationMapReverse={
                      deviceClassificationMapReverse
                    }
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />

              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={(e) => {
                      this.onWidgetClickAllDevices({
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            <Typography
              align="left"
              style={{ paddingTop: 5, marginLeft: 5, fontSize: 18 }}
              variant="button"
            >
              Total Devices: {this.total()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}

        {l4DeviceMap.includes(gridDetails.name) &&
          gridDetails.name !== 'devicesI10iAll' &&
          (this.apiSucceeded ? (
            <>
              <PieChart
                width={gridDetails.layout.w}
                height={gridDetails.layout.h * 0.7}
              >
                <Legend
                  layout="vertical"
                  align="left"
                  verticalAlign="middle"
                  content={
                    <CustomLegendPieChart
                      gridDetails={gridDetails}
                      onWidgetClick={this.onWidgetClick}
                    />
                  }
                />
                <ChartTooltip content={CustomUtilTooltipPieChart} />
                <Pie
                  innerRadius="65%"
                  outerRadius="100%"
                  isAnimationActive={false}
                  data={this.counts}
                  dataKey="value"
                  nameKey="name"
                  cursor="pointer"
                >
                  {this.counts.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                      onClick={() => {
                        this.onWidgetClick({
                          gridDetails: gridDetails,
                          value: entry.name,
                        })
                      }}
                    />
                  ))}
                </Pie>
              </PieChart>
              {this.footer(gridDetails)}
            </>
          ) : (
            <Typography className={classes.text} variant="h6">
              Note: The selected device data is internal to Target. Please
              connect to a Target network to view.
            </Typography>
          ))}

        {gridDetails.name === 'devicesHandheldModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}

        {gridDetails.name === 'devicesHandheldTypes' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    fill={COLORS}
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesHandheldManagementSystem' && (
          <>
            <BarChart
              width={gridDetails.layout.w * 0.9}
              height={gridDetails.layout.h * 0.8}
              data={this.counts}
              margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
              onClick={(e) => {
                this.onWidgetClick({
                  gridDetails: gridDetails,
                  value: e.activeLabel,
                })
              }}
            >
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <YAxis
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <ChartTooltip content={CustomUtilTooltipBarChart} />
              <Bar
                isAnimationActive={false}
                fill={COLORS[4]}
                dataKey="value"
                barSize={25}
                label={{
                  position: 'top',
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  color: '#000000',
                  fontWeight: 500,
                }}
                cursor="pointer"
              ></Bar>
            </BarChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesHandheldLocationType' && (
          <>
            <BarChart
              width={gridDetails.layout.w * 0.9}
              height={gridDetails.layout.h * 0.8}
              data={this.counts}
              margin={{ top: 20, right: 0, left: 0, bottom: 0 }}
              onClick={(e) => {
                this.onWidgetClick({
                  gridDetails: gridDetails,
                  value: e.activeLabel,
                })
              }}
            >
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <YAxis
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <ChartTooltip content={CustomUtilTooltipBarChart} />
              <Bar
                isAnimationActive={false}
                fill="#0097a7"
                dataKey="value"
                barSize={25}
                label={{
                  position: 'top',
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  color: '#000000',
                  fontWeight: 500,
                }}
                cursor="pointer"
              ></Bar>
            </BarChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesHandheldStates' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip
                content={CustomUtilTooltipPieChart}
                wrapperStyle={{ maxWidth: 250 }}
              />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS1[entry.name]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'deviceLockerStates' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS2[entry.name]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {this.props.gridDetails.name === 'devicesHandheldUtilization' && (
          <>
            <DeviceUtilization gridDetails={this.props.gridDetails} />
          </>
        )}
        {this.props.gridDetails.name === 'devicesTabletUtilization' && (
          <>
            <DeviceUtilization gridDetails={this.props.gridDetails} />
          </>
        )}
        {this.props.gridDetails.name === 'devicesTabletModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    fill={COLORS}
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesTabletTypes' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    fill={COLORS}
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesTabletManagementSystem' && (
          <>
            <BarChart
              width={gridDetails.layout.w * 0.9}
              height={gridDetails.layout.h * 0.8}
              data={this.counts}
              margin={{ top: 15, right: 0, left: 0, bottom: 0 }}
              onClick={(e) => {
                this.onWidgetClick({
                  gridDetails: gridDetails,
                  value: e.activeLabel,
                })
              }}
            >
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <YAxis
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <ChartTooltip content={CustomUtilTooltipBarChart} />
              <Bar
                isAnimationActive={false}
                fill={COLORS[4]}
                dataKey="value"
                barSize={25}
                label={{
                  position: 'top',
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  color: '#000000',
                  fontWeight: 500,
                }}
                cursor="pointer"
              ></Bar>
            </BarChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesTabletManagementSystemElo' && (
          <>
            <BarChart
              width={gridDetails.layout.w * 0.9}
              height={gridDetails.layout.h * 0.8}
              data={this.counts}
              margin={{ top: 15, right: 0, left: 0, bottom: 0 }}
              onClick={(e) => {
                this.onWidgetClick({
                  gridDetails: gridDetails,
                  value: e.activeLabel,
                })
              }}
            >
              <XAxis
                dataKey="name"
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <YAxis
                tick={{
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  fill: '#000000',
                  fontWeight: 500,
                }}
              />
              <ChartTooltip content={CustomUtilTooltipBarChart} />
              <Bar
                isAnimationActive={false}
                fill={COLORS[5]}
                dataKey="value"
                barSize={25}
                label={{
                  position: 'top',
                  fontSize: '0.875rem',
                  fontFamily: 'roboto',
                  color: '#000000',
                  fontWeight: 500,
                }}
                cursor="pointer"
              ></Bar>
            </BarChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesTabletStates' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS1[entry.name]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesPrinterModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesPrinterTypes' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {/* {gridDetails.name === 'devicesCameraModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )} */}
        {gridDetails.name === 'devicesMacModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsState' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesMacState' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareOS' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareBios' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareDomain' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareRam' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareOsVersion' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareAdOrgUnit' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareModels' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesVMWareState' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}

        {gridDetails.name === 'devicesWindowsRam' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesRFIDScannersStates' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS1[entry.name]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsOsVersion' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesRFIDScannersStatus' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsAdOrgUnit' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsBios' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsDomain' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsOS' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesMacRam' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesMacOsVersion' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesMacOS' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesBTScannersStates' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS1[entry.name]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesBTScannersStatus' && (
          <>
            <PieChart
              width={gridDetails.layout.w}
              height={gridDetails.layout.h * 0.7}
            >
              <Legend
                layout="vertical"
                align="left"
                verticalAlign="middle"
                content={
                  <CustomLegendPieChart
                    gridDetails={gridDetails}
                    onWidgetClick={this.onWidgetClick}
                  />
                }
              />
              <ChartTooltip content={CustomUtilTooltipPieChart} />
              <Pie
                innerRadius="65%"
                outerRadius="100%"
                isAnimationActive={false}
                data={this.counts}
                dataKey="value"
                nameKey="name"
                cursor="pointer"
              >
                {this.counts.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                    onClick={() => {
                      this.onWidgetClick({
                        gridDetails: gridDetails,
                        value: entry.name,
                      })
                    }}
                  />
                ))}
              </Pie>
            </PieChart>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesHandheldsAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total Handhelds: {this.handhelds()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesTabletsAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total tablets: {this.tablets()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {/* {gridDetails.name === 'devicesCamerasAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total Cameras: {this.cameras()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )} */}
        {gridDetails.name === 'devicesRFIDScannersAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total RFID Scanners: {this.rfidscanner()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesPrintersAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total Printers: {this.printers()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              All Windows Computers: {this.windows()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesWindowsVMAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              All VM's Computers: {this.windows()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesMacAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              All Mac Computers: {this.mac()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesBTScannersAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total Bluetooth Scanners: {this.bluetoothscanner()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}
        {gridDetails.name === 'devicesRegistersAll' && (
          <>
            <Typography
              align="center"
              style={{
                paddingTop: gridDetails.layout.h * 0.25,
                textTransform: 'uppercase',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              Total Registers: {this.register()}
            </Typography>
            {this.footer(gridDetails)}
          </>
        )}

        {gridDetails.name === 'devicesI10iAll' &&
          // I10i count widget
          (this.apiSucceeded ? (
            <>
              <Typography
                align="center"
                style={{
                  paddingTop: gridDetails.layout.h * 0.25,
                  textTransform: 'uppercase',
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                Total {this.props.widgetProperty}: {this.testCount}
              </Typography>
            </>
          ) : (
            <Typography className={classes.text} variant="h6">
              N/A
            </Typography>
          ))}

        {dialogOpen &&
          (dialogDetails.title !== 'Check-Out Status' ? (
            <Dialog
              open={this.state.dialogOpen}
              onClose={this.handleDialogClose}
              className={classes.dialog}
              maxWidth="md"
            >
              <DialogTitle className={classes.dialog}>
                {dialogDetails.title}
              </DialogTitle>
              <DialogContent className={classes.dialog}>
                <GridPlatforms
                  gridDetails={this.state.dialogDetails}
                ></GridPlatforms>
              </DialogContent>
              <DialogContentText className={classes.dialog}>
                {dialogDetails.text}
              </DialogContentText>
              <IconButton
                aria-label="Close"
                className={classes.dialogIcon}
                onClick={this.handleDialogClose}
              >
                <CloseIcon />
              </IconButton>
            </Dialog>
          ) : (
            <CustomCheckOutDownloadDialog
              open={this.state.dialogOpen}
              onClose={() => {
                this.handleDialogClose()
              }}
            />
          ))}
      </React.Fragment>
    )
  }
}

const CustomUtilTooltipPieChart = (props) => {
  return (
    props.active && (
      <Typography
        style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          fontWeight: 'medium',
          backgroundColor: '#666666',
          color: '#F7F7F7',
          borderRadius: 5,
          padding: '4px 8px',
          width: '150px',
          height: '50px',
        }}
      >
        <Label
          style={{
            fontSize: 24,
            color: props.payload[0].payload.fill,
            paddingRight: 5,
          }}
        />
        {props.payload[0].payload.displayName !== undefined
          ? props.payload[0].payload.displayName +
            '\n(' +
            props.payload[0].name +
            ')' +
            ': ' +
            props.payload[0].value
          : props.payload[0].payload.name + ': ' + props.payload[0].value}
      </Typography>
    )
  )
}
const CustomLegendPieChart = (props) => {
  const { payload, gridDetails, deviceClassificationMapReverse } = props
  const { count_parameters } = props.gridDetails

  let size = gridDetails.name === 'devicesAll' ? 12 : 8
  return payload.slice(0, size).map((row, index) =>
    gridDetails &&
    gridDetails.name !== 'devicesAll' &&
    gridDetails.name !== 'devicesAllEndUserEndPoint' ? (
      <HtmlTooltip
        key={index}
        title={
          <React.Fragment>
            <Typography>
              {`Click to see a list of all ${
                count_parameters.classification_name
              } ${
                l4DeviceMap.includes(gridDetails.name)
                  ? row.payload.displayName
                  : row.value
              } ${gridDetails.title}`}
            </Typography>
          </React.Fragment>
        }
      >
        <Typography
          variant="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            marginRight: 15,
            cursor: 'pointer',
          }}
          onClick={() => {
            props.onWidgetClick({
              gridDetails: props.gridDetails,
              value: row.value,
            })
          }}
        >
          <Label
            style={{
              fontSize: 24,
              color: props.payload[index].payload.fill,
              paddingRight: 5,
            }}
          />

          {l4DeviceMap.includes(gridDetails.name)
            ? row.payload.displayName.length <= 23
              ? row.payload.displayName + ': ' + row.payload.value
              : row.payload.displayName.slice(0, 10) +
                '...: ' +
                row.payload.value
            : row.value.length <= 23
              ? row.value + ': ' + row.payload.value
              : row.value.slice(0, 23) + '...: ' + row.payload.value}
          {/*row.value.length <= 23
            ? row.value + ': ' + row.payload.value
          : row.value.slice(0, 23) + '...: ' + row.payload.value*/}
        </Typography>
      </HtmlTooltip>
    ) : (
      <HtmlTooltip
        key={index}
        title={
          <React.Fragment>
            <Typography>{`Click to see all ${
              gridDetails.name === 'devicesAllEndUserEndPoint'
                ? deviceClassificationMapReverse[row.value]
                : row.value
            } devices`}</Typography>
          </React.Fragment>
        }
      >
        <Typography
          variant="button"
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 14,
            marginRight: 15,
            cursor: 'pointer',
          }}
          onClick={(e) => {
            gridDetails.name === 'devicesAll'
              ? props.onWidgetClick({
                  value: row.value,
                })
              : props.onWidgetClick({
                  deviceClassificationMapReverse:
                    deviceClassificationMapReverse,
                  gridDetails: props.gridDetails,
                  value: row.value,
                })
          }}
        >
          <Label
            style={{
              fontSize: 24,
              color: props.payload[index].payload.fill,
              paddingRight: 5,
            }}
          />
          {row.value.length <= 25
            ? gridDetails.name === 'devicesAll'
              ? row.value + ': ' + row.payload.value
              : deviceClassificationMapReverse[row.value] +
                ': ' +
                row.payload.value
            : gridDetails.name === 'devicesAll'
              ? row.value.slice(0, 25) + '...: ' + row.payload.value
              : deviceClassificationMapReverse[row.value].slice(0, 25) +
                '...: ' +
                row.payload.value}
        </Typography>
      </HtmlTooltip>
    ),
  )
}

const CustomUtilTooltipBarChart = (props) => {
  return props.payload.map(
    (row, index) =>
      props.active && (
        <Typography
          key={index}
          style={{
            display: 'flex',
            alignItems: 'center',
            fontSize: 12,
            fontWeight: 'medium',
            backgroundColor: '#666666',
            color: '#F7F7F7',
            borderRadius: 5,
            padding: '4px 8px',
          }}
        >
          <Label
            style={{
              fontSize: 24,
              color: row.fill,
              paddingRight: 5,
            }}
          />
          {row.payload.name + ': ' + row.value}
        </Typography>
      ),
  )
}
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#ffffff',
    color: '#333333',
    maxWidth: 200,
    fontSize: 30,
    border: '1px solid #666666',
    borderRadius: 5,
  },
}))(Tooltip)

const styles = {
  root: {
    justifyContent: 'space-around',
    marginTop: 5,
    // marginLeft: 25,
    // minHeight: `calc(100vh - 75px)`,
    // backgroundImage:
    //   `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='24.5' ` +
    //   `viewBox='0 0 28 49'%3E%3Cg fill-rule='evenodd'%3E%3Cg id='hexagons' fill='%23888888' fill-opacity='0.50' ` +
    //   `fill-rule='nonzero'%3E%3Cpath d='M13.99 9.25l13 7.5v15l-13 7.5L1 31.75v-15l12.99-7.5zM3 17.9v12.7l10.99 6.34 ` +
    //   `11-6.35V17.9l-11-6.34L3 17.9zM0 15l12.98-7.5V0h-2v6.35L0 12.69v2.3zm0 18.5L12.98 41v8h-2v-6.85L0 35.81v-2.3zM15 ` +
    //   `0v7.5L27.99 15H28v-2.31h-.01L17 6.35V0h-2zm0 49v-8l12.99-7.5H28v2.31h-.01L17 42.15V49h-2z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E")`,
  },
  gridList: {
    // width: 1000,
    // height: 500,
  },
  tableBorder: {
    borderBottom: 0,
    padding: '4px 4px 4px 4px',
  },
  dialogIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
  },
  dialog: {
    textAlign: 'center',
    marginTop: 15,
  },
  cardContent: {
    paddingBottom: 15,
    marginTop: 25,
    marginLeft: 25,
    paddingTop: 15,
    paddingRight: 15,
    paddingLeft: 15,
    maxHeight: '185px',
    width: '185px',
  },
  cardStyle: {
    textAlign: 'center',
    paddingRight: 0,
  },
  listItemHeader: {
    textAlign: 'center',
    paddingLeft: 2,
    paddingRight: 2,
    paddingTop: 0,
    paddingBottom: 5,
  },
  listItem: {
    color: '#B85300',
    textAlign: 'center',
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  tableRow: {
    display: 'flex',
    minHeight: 275,
    alignContent: 'center',
    justifyContent: 'space-evenly',
  },
  cell: {
    height: 10,
    padding: 'dense',
  },
  text: {
    textAlign: 'center',
    margin: 'auto',
    paddingTop: 25,
    fontStyle: 'italic',
  },
}

export default withStyles(styles)(withAnalytics()(withEnv()(Griddler)))
