import BeepDevice from '../../Dashboard/MenuActionItems/BeepDevice'
import DeleteBTScanners from '../../Dashboard/MenuActionItems/DeleteBTScanners'
import DeleteHandheld from '../../Dashboard/MenuActionItems/DeleteHandheld'
import DeletePrinter from '../../Dashboard/MenuActionItems/DeletePrinter'
import DeviceLevelOpacAction from '../../Dashboard/MenuActionItems/DeviceLevelOpacAction'
import DisableNotification from '../../Dashboard/MenuActionItems/DisableNotification'
import Divider from '@material-ui/core/Divider'
import EditBTScannersLabel from '../../Dashboard/MenuActionItems/EditBTScannersLabel'
import EditDeviceLabel from '../../Dashboard/MenuActionItems/EditDeviceLabel'
import EditPrinterHostname from '../../Dashboard/MenuActionItems/EditPrinterHostname'
import EditPrinterLabel from '../../Dashboard/MenuActionItems/EditPrinterLabel'
import MenuItem from '@material-ui/core/MenuItem'
import OfflineHandheldDevice from '../../Dashboard/MenuActionItems/OfflineHandheldDevice'
import PrinterLostStolen from '../../Dashboard/MenuActionItems/PrinterLostStolen'
import PrinterTools from '../../Dashboard/MenuActionItems/PrinterTools'
import QuarantineHandheld from '../../Dashboard/MenuActionItems/QuarantineHandheld'
import React from 'react'
import RepairHandheldDevice from '../../Dashboard/MenuActionItems/RepairHandheldDevice'
import RingDevicePrinter from '../../Dashboard/MenuActionItems/RingDevicePrinter'
import SetBTScannersLostStolen from '../../Dashboard/MenuActionItems/SetBTScannersLostStolen'
// import SetCameraFocus from '../../Dashboard/MenuActionItems/SetCameraFocus'
import SetDeviceLostStolen from '../../Dashboard/MenuActionItems/SetDeviceLostStolen'
import SetPrinterMode from '../../Dashboard/MenuActionItems/SetPrinterMode'
import SetTepDeviceStolen from '../../Dashboard/MenuActionItems/SetTepDeviceStolen'
import Spinner from 'nicollet-react/es/components/Spinner/Spinner'
import { Typography } from '@material-ui/core'
import UpdateOrgGroup from '../../Dashboard/MenuActionItems/UpdateOrgGroup'
import btscannersDeviceTypes from '../../Dashboard/deviceTypes/btscannersDeviceTypes'
// import cameraDeviceTypes from '../../Dashboard/deviceTypes/cameraDeviceTypes'
import handheldDeviceTypes from '../../Dashboard/deviceTypes/handheldDeviceTypes'
import printerDeviceTypes from '../../Dashboard/deviceTypes/printerDeviceTypes'
import svcCICOActions from '../../../services/svcCICOActions'
import { withAuth } from '@praxis/component-auth'
import { withEnv } from '@praxis/component-runtime-env'

function MenuActionItem(props) {
  return (
    <MenuItem
      onClick={() => {
        props.onClose()
        props.onClick()
      }}
    >
      {props.label}
    </MenuItem>
  )
}

class DeviceActionsMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      anchorEl: null,
      showDeviceLostStolenDialog: false,
      showStolenDeviceDialog: false,
      showDeviceLabelDialog: false,
      showBeepDeviceDialog: false,
      showDeleteDeviceDialog: false,
      showOfflineDeviceDialog: false,
      showQuarantineDeviceDialog: false,
      showRepairDeviceDialog: false,
      showPrinterDeleteDialog: false,
      showPrinterLabelDialog: false,
      showPrinterDialog: false,
      showPrinterToolsDialog: false,
      showPrinterHostnameDialog: false,
      showRingDeviceDialog: false,
      showDisableNotification: false,
      showPrinterLostStolen: false,
      // showCameraDialog: false,
      showBTScannersLostStolenDialog: false,
      showBTScannersLabelDialog: false,
      showDeleteBTScannersDialog: false,
      showOrgGroupUpdateDialog: false,
      menuDisabled: true,
      toDoAction: '',
      toDoActionValue: '',
      opacsCICO: [],
      loadingOpacs: false,
      selectedOpac: '',
    }
    this.svcCICOActions = new svcCICOActions()
  }

  componentDidMount() {
    this.setState({ loadingOpacs: true })
    this.svcCICOActions
      .getOpacDetails('T' + this.props.rowData.location_id, this.props.env)
      .then((response) => {
        if (Math.round(response.status / 100) === 2) {
          let opacsCICOTemp = response.data
          opacsCICOTemp.push({
            friendly_name: 'Status History',
            opac_name: 'Last five statuses',
          })
          this.setState({
            opacsCICO: opacsCICOTemp,
            loadingOpacs: false,
          })
        }
      })
      .catch((error) => {
        console.log('ERROR Opac-details', error)
        this.setState({
          loadingOpacs: false,
        })
      })
      .finally(() => {
        this.setState({ loadingOpacs: false })
      })
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  handleMenu = () => {
    this.setState({ menuDisabled: false })
  }

  handleBeepDeviceActionClick = (event) => {
    this.setState({ showBeepDeviceDialog: true })
  }
  handleBeepDeviceDialogClose = (event) => {
    this.setState({ showBeepDeviceDialog: false })
  }
  handleDeviceLostStolenClick = (event) => {
    this.setState({ showDeviceLostStolenDialog: true })
  }
  handleDeviceLostStolenClose = (event) => {
    this.setState({ showDeviceLostStolenDialog: false })
  }
  handleDeviceLabelActionClick = (event) => {
    this.setState({ showDeviceLabelDialog: true })
  }
  handleDeviceLabelDialogClose = (event) => {
    this.setState({ showDeviceLabelDialog: false })
  }
  handleDeleteActionClick = () => {
    this.setState({ showDeleteDeviceDialog: true })
  }
  handleDeleteDialogClose = (event) => {
    this.setState({ showDeleteDeviceDialog: false })
  }
  handleQuaratineDeviceClick = () => {
    this.setState({ showQuarantineDeviceDialog: true })
  }
  handleQuaratineDeviceDialogClose = (event) => {
    this.setState({ showQuarantineDeviceDialog: false })
  }
  handleRepairDeviceActionClick = () => {
    this.setState({ showRepairDeviceDialog: true })
  }
  handleRepairDeviceDialogClose = (event) => {
    this.setState({ showRepairDeviceDialog: false })
  }
  handleOfflineDeviceActionClick = () => {
    this.setState({ showOfflineDeviceDialog: true })
  }
  handleOfflineDeviceDialogClose = (event) => {
    this.setState({ showOfflineDeviceDialog: false })
  }
  handleOrgGroupUpdateActionClick = () => {
    this.setState({ showOrgGroupUpdateDialog: true })
  }
  handleOrgGroupUpdateDialogClose = (event) => {
    this.setState({ showOrgGroupUpdateDialog: false })
  }
  handleStolenDeviceClick = () => {
    this.setState({ showStolenDeviceDialog: true })
  }
  handleStolenDeviceDialogClose = (event) => {
    this.setState({ showStolenDeviceDialog: false })
  }

  handlePrinterToolsActionClick = (toDoAction, toDoActionValue) => {
    this.setState({
      showPrinterToolsDialog: true,
      toDoAction: toDoAction,
      toDoActionValue: toDoActionValue,
    })
  }
  handlePrinterToolsDialogClose = (event) => {
    this.setState({ showPrinterToolsDialog: false })
  }
  handlePrinterLabelActionClick = (toDoAction, toDoActionValue) => {
    this.setState({
      showPrinterLabelDialog: true,
      toDoAction: toDoAction,
      toDoActionValue: toDoActionValue,
    })
  }
  handleRingDeviceActionClick = (toDoAction, toDoActionValue) => {
    this.setState({
      showRingDeviceDialog: true,
      toDoAction: toDoAction,
      toDoActionValue: toDoActionValue,
    })
  }
  handlePrinterLabelDialogClose = (event) => {
    this.setState({ showPrinterLabelDialog: false })
  }
  handlePrinterModeActionClick = (toDoAction) => {
    this.setState({ showPrinterModeDialog: true, toDoAction: toDoAction })
  }
  handlePrinterModeDialogClose = (event) => {
    this.setState({ showPrinterModeDialog: false })
  }
  handlePrinterDeleteActionClick = (toDoAction) => {
    this.setState({ showPrinterDeleteDialog: true, toDoAction: toDoAction })
  }
  handlePrinterDeleteDialogClose = (event) => {
    this.setState({ showPrinterDeleteDialog: false })
  }
  handlePrinterHostnameActionClick = (toDoAction) => {
    this.setState({ showPrinterHostnameDialog: true, toDoAction: toDoAction })
  }
  handlePrinterHostnameDialogClose = (event) => {
    this.setState({ showPrinterHostnameDialog: false })
  }
  handleRingDeviceDialogClose = (event) => {
    this.setState({ showRingDeviceDialog: false })
  }
  handleDisableNotificationActionClick = () => {
    this.setState({ showDisableNotification: true })
  }
  handleDisableNotificationDialogClose = () => {
    this.setState({ showDisableNotification: false })
  }
  handlePrinterLostStolenActionClick = () => {
    this.setState({ showPrinterLostStolen: true })
  }
  handlePrinterLostStolenDialogClose = () => {
    this.setState({ showPrinterLostStolen: false })
  }

  // handleCameraActionClick = (toDoAction) => {
  //   this.setState({ showCameraDialog: true, toDoAction: toDoAction })
  // }
  // handleCameraActionClickDialogClose = (event) => {
  //   this.setState({ showCameraDialog: false })
  // }

  handleBTScannersLabelActionClick = (event) => {
    this.setState({ showBTScannersLabelDialog: true })
  }
  handleBTScannersLabelDialogClose = (event) => {
    this.setState({ showBTScannersLabelDialog: false })
  }
  handleBTScannersLostStolenClick = (event) => {
    this.setState({ showBTScannersLostStolenDialog: true })
  }
  handleBTScannersLostStolenClose = (event) => {
    this.setState({ showBTScannersLostStolenDialog: false })
  }
  handleDeleteBTScannersActionClick = () => {
    this.setState({ showDeleteBTScannersDialog: true })
  }
  handleDeleteBTScannersDialogClose = (event) => {
    this.setState({ showDeleteBTScannersDialog: false })
  }

  handleCICOActionClick = (opac) => {
    this.setState({
      showCICOActionDialog: true,
      selectedOpac: opac,
    })
  }
  handleCICODialogClose = (event) => {
    this.setState({
      showCICOActionDialog: false,
    })
  }

  render() {
    const {
      rowData: {
        serial_number,
        department,
        label,
        ip_address,
        hostname,
        device_state,
        is_renamed,
        management_system,
      },
      platform,
    } = this.props
    // const supportedEvents =
    //   this.props.rowData.supported_events &&
    //   this.props.rowData.supported_events.split(',')
    const { menuDisabled } = this.state
    // function hasAvailableActionCamera(action) {
    //   return (
    //     cameraDeviceTypes.hasOwnProperty(platform) &&
    //     cameraDeviceTypes[platform].hasOwnProperty('availableActions') &&
    //     cameraDeviceTypes[platform].availableActions.includes(action) &&
    //     supportedEvents &&
    //     supportedEvents.includes(action)
    //   )
    // }
    function hasAvailableAction(action) {
      return (
        handheldDeviceTypes.hasOwnProperty(department) &&
        handheldDeviceTypes[department].hasOwnProperty(
          'device_classification',
        ) &&
        handheldDeviceTypes[department].device_classification.includes(
          platform,
        ) &&
        handheldDeviceTypes[department].hasOwnProperty('availableActions') &&
        handheldDeviceTypes[department].availableActions.includes(action)
      )
    }
    function hasAvailableActionPrinter(action) {
      return (
        printerDeviceTypes.hasOwnProperty(department) &&
        printerDeviceTypes[department].hasOwnProperty(
          'device_classification',
        ) &&
        printerDeviceTypes[department].device_classification.includes(
          platform,
        ) &&
        printerDeviceTypes[department].hasOwnProperty('availableActions') &&
        printerDeviceTypes[department].availableActions.includes(action)
      )
    }
    function hasAvailableActionBTScanners(action) {
      return (
        btscannersDeviceTypes.hasOwnProperty(platform) &&
        btscannersDeviceTypes[platform].hasOwnProperty('availableActions') &&
        btscannersDeviceTypes[platform].availableActions.includes(action)
      )
    }
    return (
      <>
        {/* handheld/tablet action item here */}
        {hasAvailableAction('setLostStolen') &&
          (device_state === 'Online' ||
            device_state === 'Offline' ||
            device_state === 'Bonded' ||
            device_state === 'Repair') &&
          this.props.env.access.handheldLostStolenMove.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleDeviceLostStolenClick()}
              label={management_system === 'Airwatch' ? 'Lost/Stolen' : 'Lost'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('setStolen') &&
          management_system === 'TEP' &&
          (device_state === 'Online' ||
            device_state === 'Offline' ||
            device_state === 'Bonded' ||
            device_state === 'Lost' ||
            device_state === 'Repair' ||
            device_state === 'Enrolled') &&
          this.props.env.access.tepStolenMove.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleStolenDeviceClick()}
              label={'Stolen'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('findDevice') &&
          management_system === 'Airwatch' &&
          device_state === 'Online' &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleBeepDeviceActionClick()}
              label={'Ring Device'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('editDeviceLabel') &&
          (device_state === 'Online' ||
            device_state === 'Offline' ||
            device_state === 'Lost') &&
          (this.props.env.access.editDeviceLabelByJobCodeId.includes(
            this.props.auth.session.identity.jobtitlecode,
          ) ||
            this.props.env.access.labelGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            )) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={this.handleDeviceLabelActionClick}
              label={'Edit Device Label'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('deleteHandheld') &&
          management_system === 'Airwatch' &&
          (device_state === 'Lost' ||
            device_state === 'Converted' ||
            device_state === 'Repair') &&
          this.props.env.access.deleteGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleDeleteActionClick()}
              label={'Delete Device'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('markDeviceQuarantine') &&
          management_system === 'TEP' &&
          (device_state === 'Online' ||
            device_state === 'Offline' ||
            device_state === 'Bonded' ||
            device_state === 'Lost' ||
            device_state === 'Repair' ||
            device_state === 'Enrolled') &&
          this.props.env.access.quarantineDeviceGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleQuaratineDeviceClick()}
              label={'Quarantine Device'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('deleteRepairDevice') &&
          (device_state === 'Online' ||
            device_state === 'Offline' ||
            device_state === 'Bonded' ||
            device_state === 'Lost' ||
            device_state === 'Quarantined' ||
            device_state === 'Stolen') &&
          this.props.env.access.repairDevice.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleRepairDeviceActionClick()}
              label={'Repair Device'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('markOfflineHandheldDevice') &&
          device_state === 'Bonded' &&
          this.props.env.access.offlineDeviceStateUpdateUserGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleOfflineDeviceActionClick()}
              label={'Offline Device'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableAction('updateOrgGroup') &&
          management_system === 'Airwatch' &&
          this.props.env.access.eloActionGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleOrgGroupUpdateActionClick()}
              label={'Update Organization Group'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        <Divider />
        {hasAvailableAction('tepOpacs') &&
          management_system === 'TEP' &&
          device_state === 'Online' &&
          (menuDisabled && this.handleMenu(),
          this.state.loadingOpacs === true ? (
            <Spinner />
          ) : (
            this.state.opacsCICO &&
            this.state.opacsCICO.length !== 0 &&
            this.state.opacsCICO.map((opac) => (
              <MenuActionItem
                key={opac.opac_name}
                onClick={() => this.handleCICOActionClick(opac)}
                label={opac.friendly_name ? opac.friendly_name : opac.opac_name}
                onClose={() => {
                  this.handleClose()
                }}
              />
            ))
          ))}
        {/* printer action item here */}
        {hasAvailableActionPrinter('setPrinterMode') &&
          this.props.env.access.printerModeGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          device_state === 'Online' &&
          typeof ip_address === 'string' &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Printer Mode
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handlePrinterModeActionClick('zplstar')}
                label={'Set to ZPL *'}
                onClose={() => {
                  this.handleClose()
                }}
              />
              <MenuActionItem
                onClick={() => this.handlePrinterModeActionClick('pdf')}
                label={'Set to PDF'}
                onClose={() => {
                  this.handleClose()
                }}
              />
              {/* <MenuActionItem
                onClick={() => this.handlePrinterModeActionClick('ezprint')}
                label={'Set to EZPRINT'}
                onClose={() => {
                  this.handleClose()
                }}
              />
              <MenuActionItem
                onClick={() => this.handlePrinterModeActionClick('zpl')}
                label={'Set to ZPL'}
                onClose={() => {
                  this.handleClose()
                }}
              /> */}
            </div>
          ))}
        {hasAvailableActionPrinter('printerTools') &&
          device_state === 'Online' &&
          this.props.env.access.printerToolsActionGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          typeof ip_address === 'string' &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Printer Tools
              </Typography>
              <Divider />
              {hasAvailableActionPrinter('printerTOF') && (
                <MenuActionItem
                  // TOF36plus
                  onClick={() =>
                    this.handlePrinterToolsActionClick('TOF36plus')
                  }
                  label={'Set Printer TOF X0 Y0'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              )}
              {/* {hasAvailableActionPrinter('printerTest') && (
                <MenuActionItem
                  // testPrintID
                  onClick={() =>
                    this.handlePrinterToolsActionClick('printLabel', hostname)
                  }
                  label={'Reprint Barcode Label'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              )} */}
              {hasAvailableActionPrinter('printerTest') && (
                <MenuActionItem
                  // testPrintID
                  onClick={() =>
                    this.handlePrinterToolsActionClick(
                      'printLabel',
                      'Hello MICKRA',
                    )
                  }
                  label={'Send Test Print'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              )}
              {/* {hasAvailableActionPrinter(`printer${model}Reprint`) && (
                <MenuActionItem
                  // testPrintID
                  onClick={() =>
                    this.handlePrinterToolsActionClick(
                      `printLabelBorder${model}`,
                      hostname
                    )
                  }
                  label={'Reprint Barcode Label'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              )} */}
              {hasAvailableActionPrinter(`printerZT410Test`) && (
                <MenuActionItem
                  // testPrintID
                  onClick={() =>
                    this.handlePrinterToolsActionClick(
                      `printZTPrintheadTest`,
                      'Hello MICKRA',
                    )
                  }
                  label={'Send Test Print'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              )}
              {hasAvailableActionPrinter('printerRebase') &&
                this.props.env.access.printerRebaseGroup.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) && (
                  <MenuActionItem
                    // idealSettings
                    onClick={() =>
                      this.handlePrinterToolsActionClick('idealSettings')
                    }
                    label={'Set Printer Base Configuration'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )}
              {hasAvailableActionPrinter(`printerZT410Rebase`) &&
                this.props.env.access.printerRebaseGroup.some((r) =>
                  this.props.auth.session.userInfo.memberOf.includes(
                    r.toUpperCase(),
                  ),
                ) && (
                  <MenuActionItem
                    // idealSettings
                    onClick={() =>
                      this.handlePrinterToolsActionClick(`idealSettingsZT410`)
                    }
                    label={'Set Printer Base Configuration'}
                    onClose={() => {
                      this.handleClose()
                    }}
                  />
                )}
              {hasAvailableActionPrinter('printerReboot') && (
                <MenuActionItem
                  // reboot
                  onClick={() => this.handlePrinterToolsActionClick('reboot')}
                  label={'Reboot Printer'}
                  onClose={() => {
                    this.handleClose()
                  }}
                />
              )}
            </div>
          ))}
        {hasAvailableActionPrinter('editPrinterHostname') &&
          this.props.env.access.printerRenameGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          device_state === 'Online' &&
          typeof ip_address === 'string' &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Printer Rename
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() =>
                  this.handlePrinterHostnameActionClick('device.friendly_name')
                }
                label={'Printer Rename'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('printerDelete') &&
          this.props.env.access.printerDeleteGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Printer Delete
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() =>
                  this.handlePrinterDeleteActionClick('device.friendly_name')
                }
                label={'Printer Delete'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('editPrinterLabel') &&
          (this.props.env.access.editDeviceLabelByJobCodeId.includes(
            this.props.auth.session.identity.jobtitlecode,
          ) ||
            this.props.env.access.printerLabelGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            )) &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Edit Printer Label
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handlePrinterLabelActionClick()}
                label={'Edit Printer Label'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('ringDevice') &&
          (this.props.env.access.editDeviceLabelByJobCodeId.includes(
            this.props.auth.session.identity.jobtitlecode,
          ) ||
            this.props.env.access.ringDeviceGroup.some((r) =>
              this.props.auth.session.userInfo.memberOf.includes(
                r.toUpperCase(),
              ),
            )) &&
          device_state === 'Online' &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Ring Printer
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handleRingDeviceActionClick('beep', '240')}
                label={'Ring Printer'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('disableNotification') &&
          is_renamed === true &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Clear Rename Notification
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handleDisableNotificationActionClick()}
                label={'Clear Rename Notification'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('launchWebPortal') &&
          this.props.env.access.printerLexmarkLaunchWebGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Launch Admin Web Portal
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => {
                  hostname.match(/^[T]\d{4}[P][R][T][2][0][0][1]$/gi)
                    ? window.open(`http://${hostname}.kiosk.target.com`)
                    : window.open(`http://${hostname}.stores.target.com`)
                }}
                label={'Launch Admin Web Portal'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('printerLostStolen') &&
          (device_state === 'Online' || device_state === 'Offline') &&
          this.props.env.access.printerLostGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Printer Lost/Stolen
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handlePrinterLostStolenActionClick()}
                label={'Printer Lost/Stolen'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {hasAvailableActionPrinter('printerZT410LostStolen') &&
          (device_state === 'Online' || device_state === 'Offline') &&
          this.props.env.access.printerZT410LostGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Printer Lost/Stolen
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handlePrinterLostStolenActionClick()}
                label={'Printer Lost/Stolen'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))}
        {/* camera action item here */}
        {/* {hasAvailableActionCamera('RemoteFocus') &&
          this.props.env.access.cameraAdminGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <div>
              <Typography
                style={{
                  textAlign: 'Center',
                  backgroundColor: '#F7DA5E',
                  textTransform: 'uppercase',
                }}
                variant="subtitle2"
              >
                Camera Focus
              </Typography>
              <Divider />
              <MenuActionItem
                onClick={() => this.handleCameraActionClick('CameraFocus')}
                label={'Camera Focus'}
                onClose={() => {
                  this.handleClose()
                }}
              />
            </div>
          ))} */}
        {/* btscanners action item here */}
        {hasAvailableActionBTScanners('btscannersLostStolen') &&
          (device_state === 'Online' || device_state === 'Offline') &&
          this.props.env.access.bluetoothScannerLostStolenMove.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleBTScannersLostStolenClick()}
              label={'Lost/Stolen'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableActionBTScanners('editBTScannersLabel') &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={this.handleBTScannersLabelActionClick}
              label={'Edit Bluetooth Scanner Label'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {hasAvailableActionBTScanners('deleteBTScanners') &&
          this.props.env.access.deleteBTScannerGroup.some((r) =>
            this.props.auth.session.userInfo.memberOf.includes(r.toUpperCase()),
          ) &&
          (menuDisabled && this.handleMenu(),
          (
            <MenuActionItem
              onClick={() => this.handleDeleteBTScannersActionClick()}
              label={'Delete Bluetooth Scanner'}
              onClose={() => {
                this.handleClose()
              }}
            />
          ))}
        {menuDisabled && (
          <div>
            <MenuItem disabled>No Available Actions</MenuItem>
          </div>
        )}
        {/* handheld/tablet action item here */}
        {this.state.showDeviceLostStolenDialog && (
          <SetDeviceLostStolen
            open={this.state.showDeviceLostStolenDialog}
            onClose={() => {
              this.handleDeviceLostStolenClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showStolenDeviceDialog && (
          <SetTepDeviceStolen
            open={this.state.showStolenDeviceDialog}
            onClose={() => {
              this.handleStolenDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showBeepDeviceDialog && (
          <BeepDevice
            open={this.state.showBeepDeviceDialog}
            onClose={() => {
              this.handleBeepDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDeviceLabelDialog && (
          <EditDeviceLabel
            open={this.state.showDeviceLabelDialog}
            onClose={() => {
              this.handleDeviceLabelDialogClose()
            }}
            deviceLabel={label}
            serialNumber={serial_number}
            {...this.props}
          />
        )}
        {this.state.showDeleteDeviceDialog && (
          <DeleteHandheld
            open={this.state.showDeleteDeviceDialog}
            onClose={() => {
              this.handleDeleteDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showQuarantineDeviceDialog && (
          <QuarantineHandheld
            open={this.state.showQuarantineDeviceDialog}
            onClose={() => {
              this.handleQuaratineDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showRepairDeviceDialog && (
          <RepairHandheldDevice
            open={this.state.showRepairDeviceDialog}
            onClose={() => {
              this.handleRepairDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showOfflineDeviceDialog && (
          <OfflineHandheldDevice
            open={this.state.showOfflineDeviceDialog}
            onClose={() => {
              this.handleOfflineDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showOrgGroupUpdateDialog && (
          <UpdateOrgGroup
            open={this.state.showOrgGroupUpdateDialog}
            onClose={() => {
              this.handleOrgGroupUpdateDialogClose()
            }}
            {...this.props}
          />
        )}
        {/* printer action item here */}
        {this.state.showPrinterModeDialog && (
          <SetPrinterMode
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterModeDialog}
            onClose={() => {
              this.handlePrinterModeDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterToolsDialog && (
          <PrinterTools
            toDoAction={this.state.toDoAction}
            toDoActionValue={this.state.toDoActionValue}
            open={this.state.showPrinterToolsDialog}
            onClose={() => {
              this.handlePrinterToolsDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterHostnameDialog && (
          <EditPrinterHostname
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterHostnameDialog}
            onClose={() => {
              this.handlePrinterHostnameDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterDeleteDialog && (
          <DeletePrinter
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterDeleteDialog}
            onClose={() => {
              this.handlePrinterDeleteDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterLabelDialog && (
          <EditPrinterLabel
            toDoAction={this.state.toDoAction}
            open={this.state.showPrinterLabelDialog}
            onClose={() => {
              this.handlePrinterLabelDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showRingDeviceDialog && (
          <RingDevicePrinter
            toDoAction={this.state.toDoAction}
            toDoActionValue={this.state.toDoActionValue}
            open={this.state.showRingDeviceDialog}
            onClose={() => {
              this.handleRingDeviceDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDisableNotification && (
          <DisableNotification
            open={this.state.showDisableNotification}
            onClose={() => {
              this.handleDisableNotificationDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showPrinterLostStolen && (
          <PrinterLostStolen
            open={this.state.showPrinterLostStolen}
            onClose={() => {
              this.handlePrinterLostStolenDialogClose()
            }}
            {...this.props}
          />
        )}
        {/* camera action item here */}
        {/* {this.state.showCameraDialog && (
          <SetCameraFocus
            toDoAction={this.state.toDoAction}
            open={this.state.showCameraDialog}
            onClose={() => {
              this.handleCameraActionClickDialogClose()
            }}
            {...this.props}
          />
        )} */}
        {/* btscanners action item here */}
        {this.state.showBTScannersLostStolenDialog && (
          <SetBTScannersLostStolen
            open={this.state.showBTScannersLostStolenDialog}
            onClose={() => {
              this.handleBTScannersLostStolenClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showBTScannersLabelDialog && (
          <EditBTScannersLabel
            open={this.state.showBTScannersLabelDialog}
            onClose={() => {
              this.handleBTScannersLabelDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showDeleteBTScannersDialog && (
          <DeleteBTScanners
            open={this.state.showDeleteBTScannersDialog}
            onClose={() => {
              this.handleDeleteBTScannersDialogClose()
            }}
            {...this.props}
          />
        )}
        {this.state.showCICOActionDialog && (
          <DeviceLevelOpacAction
            open={this.state.showCICOActionDialog}
            onClose={() => {
              this.handleCICODialogClose()
            }}
            {...this.props}
            data={this.state.selectedOpac}
          />
        )}
      </>
    )
  }
}

export default withAuth(null)(withEnv()(DeviceActionsMenu))
